export const emailVerify = {
  title: 'Check your inbox for a link to verify your email address',
  details: 'A link to verify your email address has been sent to ',
  checkSpam: 'Check your spam folder if you don’t see it.',
  resend: 'Resend verification email',
  csHelp: 'Need help?',
  cs: 'Contact Customer Care',
  error: 'An error occurred while verifying your email address. Please try again.',
  sent: 'Check your inbox for a link to verify your email address',
}
