import { Body, Box, CardText, CardTextSizeEnum, DiagonalMutedGradient2, ICONS } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'

import northoneDebitCardUrl from '@/assets/images/north-one-debit-card.svg'
import ContinueButton from '@/components/ContinueButton'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'

interface FinishUpLandingMRScreenProps {
  continueButtonPath: () => void
}

export default function FinishUpLandingMRScreen({ continueButtonPath }: FinishUpLandingMRScreenProps) {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()

  const title = t('finishUp.landing.manualReviewLanding.title')
  const subtitle = t('finishUp.landing.manualReviewLanding.subtitle')
  const nextUp = t('finishUp.landing.manualReviewLanding.nextUp')
  const takePhotos = t('finishUp.landing.manualReviewLanding.takePhotos')
  const formationDocuments = t('finishUp.landing.manualReviewLanding.formationOfDocuments')
  const DBA = t('finishUp.landing.manualReviewLanding.businessLicense')

  const primaryButton = <ContinueButton testID="finish-up-landing-continue" onPress={continueButtonPath} fullWidth />

  return (
    <ExpectationScreenLayout primaryButton={isMobileSize && primaryButton} disclaimerComponent={<StandardDisclaimer />}>
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf primaryButton={!isMobileSize && primaryButton}>
          <Box sx={{ gap: '$4' }}>
            <Box sx={{ gap: '$2' }}>
              <Body>{nextUp}</Body>
              <Box sx={{ gap: '$6' }}>
                <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
                <CardText size={CardTextSizeEnum.XLG}>{subtitle}</CardText>
              </Box>
            </Box>
            <Box sx={{ gap: '$2' }}>
              <ListItem icon={ICONS.IdentificationIcon}>{takePhotos}</ListItem>
              <ListItem icon={ICONS.DocumentIcon}>{formationDocuments}</ListItem>
              <ListItem icon={ICONS.CertifiedIcon}>{DBA}</ListItem>
            </Box>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <Box sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <DiagonalMutedGradient2>
              <Box sx={{ paddingVertical: '$8', alignItems: 'center' }}>
                <img
                  src={northoneDebitCardUrl}
                  style={{ maxHeight: 350, maxWidth: '100%' }}
                  width="401"
                  height="350"
                  alt="North One debit card"
                />
              </Box>
            </DiagonalMutedGradient2>
          </Box>
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
