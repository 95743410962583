export const getURLSearchParameter = (parameter: string) =>
  new URLSearchParams(window.location.search).get(parameter) ?? undefined

const clickIdSearchParamToAttributionDataKey = {
  gclid: 'googleClickId',
  fbclid: 'facebookClickId',
  li_fat_id: 'linkedInClickId',
  msclkid: 'microsoftClickId',
  ttclid: 'tikTokClickId',
} as const

export const urlParamsToAttributionData = (urlParams: URLSearchParams) => {
  const attributionData: Record<string, string> = {}
  for (const [searchParam, attributionDataKey] of Object.entries(clickIdSearchParamToAttributionDataKey)) {
    const searchParamValue = urlParams.get(searchParam)
    if (searchParamValue) {
      attributionData[attributionDataKey] = searchParamValue
    }
  }
  return attributionData
}
