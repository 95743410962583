import { Body, Box, CardText, CardTextSizeEnum, ICONS } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import adKYCCorpPartnershipUrl from '@/assets/images/kyc-partnership-corp-graphic.webp'
import ContinueButton from '@/components/ContinueButton'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenImage,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { Pathname } from '@/routes/constants'
import { SSNModal } from '@/routes/welcome/components/SSNModal'

export default function CorporationPartnershipLanding() {
  const t = useOnboardingTranslations()
  const navigate = useNavigate()
  const nextPath = Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER
  const { isMobileSize } = useMobileScreenSize()

  const [SSNModalVisible, setSSNModalVisible] = useState(false)

  const toggleSSNModal = () => {
    setSSNModalVisible(!SSNModalVisible)
  }

  const onClose = () => {
    setSSNModalVisible(false)
  }

  const onContinue = () => {
    navigate(nextPath)
  }

  const nextUp = t('ownership.landing.nextUp')
  const title = t('ownership.landing.corporation.title')
  const subtitle = t('ownership.landing.corporation.subtitle')

  const SSN = t('ownership.landing.willNeed.SSN')
  const personal = t('ownership.landing.willNeed.personal')
  const ownership = t('ownership.landing.willNeed.ownership')
  const financialAdmin = t('ownership.landing.willNeed.financialAdmin')

  const primaryButton = (
    <ContinueButton testID="ownership-non-soleprop-landing-continue" onPress={onContinue} fullWidth />
  )

  return (
    <ExpectationScreenLayout primaryButton={isMobileSize && primaryButton} disclaimerComponent={<StandardDisclaimer />}>
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf primaryButton={!isMobileSize && primaryButton}>
          <Box sx={{ gap: '$6' }}>
            <Box sx={{ gap: '$2' }}>
              <Body>{nextUp}</Body>
              <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
            </Box>
            <Box sx={{ gap: '$4' }}>
              <CardText color={'$charcoal7'} size={CardTextSizeEnum.XLG}>
                {subtitle}
              </CardText>
              <Box sx={{ gap: '$2' }}>
                <ListItem icon={ICONS.ProtectedIcon} onClick={toggleSSNModal} showInformationIcon>
                  {SSN}
                </ListItem>
                <ListItem icon={ICONS.IdentificationIcon}>{personal}</ListItem>
                <ListItem icon={ICONS.PieChartIcon}>{ownership}</ListItem>
                <ListItem icon={ICONS.ControllerIcon}>{financialAdmin}</ListItem>
              </Box>
            </Box>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <ExpectationScreenImage
            src={adKYCCorpPartnershipUrl}
            alt="NorthOne company cards for every owner, give every co-owner the access and visibility they need with unique logins"
          />
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
      <SSNModal isSSNVisible={SSNModalVisible} onClose={onClose} />
    </ExpectationScreenLayout>
  )
}
