import { Body, Box, CardText, CardTextSizeEnum, DiagonalMutedGradient2, ICONS } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'

import northoneDebitCardUrl from '@/assets/images/north-one-debit-card.svg'
import ContinueButton from '@/components/ContinueButton'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'

interface FinishUpFundingLandingScreenProps {
  formattedHomeAddress: string | undefined
  addressesAreSame: boolean
  continueButtonPath: () => void
}

export default function FinishUpFundingLandingScreen({
  formattedHomeAddress,
  addressesAreSame,
  continueButtonPath,
}: FinishUpFundingLandingScreenProps) {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()

  const title = t('finishUp.landing.funding.sameAddress.title')
  const subtitle = t('finishUp.landing.funding.sameAddress.subtitle')
  const makeInPersonPurchases = t('finishUp.landing.funding.sameAddress.makeInPersonPurchases')
  const makeOnlinePurchases = t('finishUp.landing.funding.sameAddress.makeOnlinePurchases')
  // different addresses:
  const differentAddressesTitle = t('finishUp.landing.funding.differentAddress.title')
  const differentAddressesSubtitle = t('finishUp.landing.funding.differentAddress.subtitle')
  const nextUp = t('finishUp.landing.funding.differentAddress.nextUp')

  const primaryButton = (
    <ContinueButton testID="finish-up-funding-landing-continue" onPress={continueButtonPath} fullWidth />
  )

  return (
    <ExpectationScreenLayout primaryButton={isMobileSize && primaryButton} disclaimerComponent={<StandardDisclaimer />}>
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf primaryButton={!isMobileSize && primaryButton}>
          <Box sx={{ gap: '$4' }}>
            {formattedHomeAddress && addressesAreSame ? (
              <>
                <Box sx={{ gap: '$6' }}>
                  <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
                  <CardText size={CardTextSizeEnum.XLG}>{subtitle}</CardText>
                </Box>
                <Box sx={{ gap: '$2' }}>
                  <ListItem icon={ICONS.WalletIcon}>{makeInPersonPurchases}</ListItem>
                  <ListItem icon={ICONS.UsageIcon}>{makeOnlinePurchases}</ListItem>
                </Box>
              </>
            ) : (
              <Box sx={{ gap: '$2' }}>
                <Body>{nextUp}</Body>
                <Box sx={{ gap: '$6' }}>
                  <ResponsiveOnboardingHeader largerSize="md">{differentAddressesTitle}</ResponsiveOnboardingHeader>
                  <CardText size={CardTextSizeEnum.XLG}>{differentAddressesSubtitle}</CardText>
                </Box>
              </Box>
            )}
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <Box sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <DiagonalMutedGradient2>
              <Box sx={{ paddingVertical: '$8', alignItems: 'center' }}>
                <img
                  src={northoneDebitCardUrl}
                  style={{ maxHeight: 350, maxWidth: '100%' }}
                  width="401"
                  height="350"
                  alt="North One debit card"
                />
              </Box>
            </DiagonalMutedGradient2>
          </Box>
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
