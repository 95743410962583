import { gql } from '@apollo/client'

export const REGISTER_USER = gql`
  mutation registerUser($phone: String, $attributionData: JSON) {
    registerUser(phone: $phone, attributionData: $attributionData) {
      code
      success
      message
      user {
        id
        businessId
        phone
      }
    }
  }
`

export const EMAIL_VERIFIED = gql`
  query emailVerified {
    me {
      id
      emailVerified
    }
  }
`

export const TERMS_AND_CONDITIONS = gql`
  query termsAndConditionsScreen {
    esignAgreement: agreementsPDF(type: ESIGN_AGREEMENT) {
      pdfURL
    }

    accountAgreement: agreementsPDF(type: ACCOUNT_AGREEMENT) {
      pdfURL
    }

    mobilePaymentServicesAgreement: agreementsPDF(type: MOBILE_PAYMENT_SERVICES_AGREEMENT) {
      pdfURL
    }

    termsAndConditionsPDF {
      url
      documentReference
    }
  }
`

export const ACCEPT_TERMS_AND_CONDTIONS = gql`
  mutation onboardingAgreementsTermsAndConditionsAccept($documentReference: String!, $businessId: String!) {
    onboardingAgreementsTermsAndConditionsAccept(documentReference: $documentReference, businessId: $businessId) {
      code
      success
      message
    }
  }
`
