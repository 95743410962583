import {
  AlertTypeEnum,
  Box,
  Checkbox,
  InlineAlert,
  SectionHeader,
  SectionHeaderSizeEnum,
  TextInput,
  Tooltip,
} from '@northone/ui-components'
import { unformat } from 'accounting'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import Fieldset from '@/components/Fieldset'
import FieldsetContainer from '@/components/FieldsetContainer'
import GoBackButton from '@/components/GoBackButton'
import LoadingScreen from '@/components/LoadingScreen'
import WhyDoWeCollectThisInfo from '@/components/WhyDoWeCollectThisInfo'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { IOnlinePresenceState } from '@/core/redux/application-redux/application-state'
import { transformBusinessTypeToGQLInput } from '@/core/redux/application-redux/selectors'
import { useAppSelector } from '@/core/redux/utils'
import { useCheckOnboardingApplicationEligibilityMutation } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { validate } from '@/utils/validate'

import { Pathname } from '../constants'

/**
 * Captures online/ecommerce details. Also checks application eligibility, which can result in a refused application
 */
export default function BusinessDetailsOnlinePresence() {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const [websiteErrorText, setWebsiteErrorText] = useState<string>()
  const [primarySocialMediaErrorText, setPrimarySocialMediaErrorText] = useState<string>()
  const { businessId, businessType, businessRevenue } = useAppSelector((state) => state.application)
  const { onlinePresence } = useAppSelector((state) => state.application)

  const [checkOnboardingApplicationEligibility, { error: checkApplicationEligibilityError }] =
    useCheckOnboardingApplicationEligibilityMutation({
      variables: {
        data: {
          business: {
            id: businessId,
            businessType: transformBusinessTypeToGQLInput(businessType),
            annualRevenue: unformat(businessRevenue),
          },
        },
      },
    })

  const onContinue = () => {
    setShouldShowLoadingScreen(true)
    checkOnboardingApplicationEligibility()
      .then(({ data }) => {
        if (!data) {
          analytics.eligibilityCheck.eligibilityCheckError()
          throw new Error('Error reviewing business')
        }
        const { isEligible } = data.checkOnboardingApplicationEligibility.data
        if (!isEligible) {
          navigate(Pathname.REJECTED, { replace: true })
          return
        }

        onlinePresence.website && analytics.application.fillWebsite({ url: onlinePresence.website })
        onlinePresence.primarySocialMedia &&
          analytics.application.fillPrimarySocialMediaAccount({ url: onlinePresence.primarySocialMedia })
        analytics.application.fillRevenueSources({
          Amazon: onlinePresence.usesAmazon ?? false,
          CashApp: onlinePresence.usesCashApp ?? false,
          Etsy: onlinePresence.usesEtsy ?? false,
          PayPal: onlinePresence.usesPayPal ?? false,
          Shopify: onlinePresence.usesShopify ?? false,
          Square: onlinePresence.usesSquare ?? false,
          Stripe: onlinePresence.usesStripe ?? false,
          Other: onlinePresence.otherRevenueProvider,
        })

        navigate(Pathname.OWNERSHIP_DETAILS)
      })
      .catch(() => {
        setShouldShowLoadingScreen(false)
      })
  }
  const onBack = () => {
    navigate(Pathname.BUSINESS_DETAILS_EXPECTED_REVENUE)
  }

  if (shouldShowLoadingScreen) {
    const loadingScreenTitle = t('businessDetails.onlinePresence.reviewingBusiness')
    return <LoadingScreen title={loadingScreenTitle} />
  }

  const title = t('businessDetails.onlinePresence.title')
  const websiteLabel = t('businessDetails.onlinePresence.websiteLabel')
  const socialMediaLabel = t('businessDetails.onlinePresence.socialMediaLabel')
  const socialMediaHelperText = t('businessDetails.onlinePresence.socialMediaHelperText')
  const errorText = t('businessDetails.onlinePresence.errorText')
  const otherRevenueProviderLabel = t('businessDetails.onlinePresence.otherRevenueProviderLabel')
  const otherLabel = t('businessDetails.onlinePresence.otherLabel')
  const invalidURLMessage = t('businessDetails.onlinePresence.invalidURLMessage')
  const revenueProvidersHeader = t('businessDetails.onlinePresence.revenueProvidersHeader')

  const patchOnlinePresence = (data: IOnlinePresenceState) => {
    dispatch(applicationActions.patchOnlinePresence(data))
  }

  const onWebsiteChanged = (website?: string) => {
    setWebsiteErrorText(validate.optionalWebsite(website) ? '' : invalidURLMessage)
    patchOnlinePresence({ website })
  }
  const onPrimarySocialMediaChanged = (primarySocialMedia?: string) => {
    setPrimarySocialMediaErrorText(validate.optionalWebsite(primarySocialMedia) ? '' : invalidURLMessage)
    patchOnlinePresence({ primarySocialMedia })
  }
  const onUsesAmazonChanged = () => patchOnlinePresence({ usesAmazon: !onlinePresence.usesAmazon })
  const onUsesCashAppChanged = () => patchOnlinePresence({ usesCashApp: !onlinePresence.usesCashApp })
  const onUsesEtsyChanged = () => patchOnlinePresence({ usesEtsy: !onlinePresence.usesEtsy })
  const onUsesSquareChanged = () => patchOnlinePresence({ usesSquare: !onlinePresence.usesSquare })
  const onUsesOtherRevenueProviderChanged = () => {
    const usesOtherRevenueProvider = !onlinePresence.usesOtherRevenueProvider
    if (!usesOtherRevenueProvider) patchOnlinePresence({ otherRevenueProvider: undefined })
    patchOnlinePresence({ usesOtherRevenueProvider })
  }
  const onOtherRevenueProviderChanged = (otherRevenueProvider?: string) => patchOnlinePresence({ otherRevenueProvider })
  const onUsesPayPalChanged = () => patchOnlinePresence({ usesPayPal: !onlinePresence.usesPayPal })
  const onUsesShopifyChanged = () => patchOnlinePresence({ usesShopify: !onlinePresence.usesShopify })
  const onUsesStripeChanged = () => patchOnlinePresence({ usesStripe: !onlinePresence.usesStripe })

  const TooltipSubheading = () => (
    <Tooltip
      content={t('businessDetails.onlinePresence.whyDoWeCollectOnlinePresence')}
      title={t('tooltip.whyDoWeCollectThisInformation')}
    >
      <WhyDoWeCollectThisInfo />
    </Tooltip>
  )

  return (
    <BaseContentLayout
      tooltipSubheading={<TooltipSubheading />}
      titleZIndex={3}
      headingText={title}
      primaryButton={<ContinueButton testID="business-online-presence-continue" onPress={onContinue} fullWidth />}
      secondaryButton={<GoBackButton testID="business-online-presence-back" onPress={onBack} fullWidth />}
      alertComponent={
        checkApplicationEligibilityError && <InlineAlert type={AlertTypeEnum.Error}>{errorText}</InlineAlert>
      }
    >
      <FieldsetContainer>
        <Fieldset>
          <TextInput
            testID="website"
            labelText={websiteLabel}
            value={onlinePresence.website ?? ''}
            placeholder="https://"
            onChange={onWebsiteChanged}
            errorText={websiteErrorText}
          />
          <TextInput
            testID="primary-social-media"
            labelText={socialMediaLabel}
            helperText={socialMediaHelperText}
            value={onlinePresence.primarySocialMedia ?? ''}
            placeholder="https://"
            onChange={onPrimarySocialMediaChanged}
            errorText={primarySocialMediaErrorText}
          />
        </Fieldset>

        <SectionHeader size={SectionHeaderSizeEnum.MD} headingLevel={2}>
          {revenueProvidersHeader}
        </SectionHeader>

        <Box sx={{ flexDirection: 'row' }}>
          <Fieldset>
            <Checkbox
              labelText="PayPal"
              value={''}
              isChecked={Boolean(onlinePresence.usesPayPal)}
              onChange={onUsesPayPalChanged}
            />
            <Checkbox
              labelText="Stripe"
              value={''}
              isChecked={Boolean(onlinePresence.usesStripe)}
              onChange={onUsesStripeChanged}
            />
            <Checkbox
              labelText="Amazon"
              value={''}
              isChecked={Boolean(onlinePresence.usesAmazon)}
              onChange={onUsesAmazonChanged}
            />
            <Checkbox
              labelText="Cash App"
              value={''}
              isChecked={Boolean(onlinePresence.usesCashApp)}
              onChange={onUsesCashAppChanged}
            />
          </Fieldset>

          <Fieldset>
            <Checkbox
              labelText="Square"
              value={''}
              isChecked={Boolean(onlinePresence.usesSquare)}
              onChange={onUsesSquareChanged}
            />
            <Checkbox
              labelText="Shopify"
              value={''}
              isChecked={Boolean(onlinePresence.usesShopify)}
              onChange={onUsesShopifyChanged}
            />
            <Checkbox
              labelText="Etsy"
              value={''}
              isChecked={Boolean(onlinePresence.usesEtsy)}
              onChange={onUsesEtsyChanged}
            />
            <Checkbox
              labelText={otherLabel}
              value={''}
              isChecked={Boolean(onlinePresence.usesOtherRevenueProvider)}
              onChange={onUsesOtherRevenueProviderChanged}
            />
          </Fieldset>
        </Box>

        <Fieldset>
          {Boolean(onlinePresence.usesOtherRevenueProvider ?? onlinePresence.otherRevenueProvider) && (
            <TextInput
              testID="other-revenue-provider"
              labelText={otherRevenueProviderLabel}
              value={onlinePresence.otherRevenueProvider ?? ''}
              onChange={onOtherRevenueProviderChanged}
            />
          )}
        </Fieldset>
      </FieldsetContainer>
    </BaseContentLayout>
  )
}
