import { Body, Box, CardText, CardTextSizeEnum, ICONS, LegalDisclaimerText } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useNavigate } from 'react-router-dom'

import adUsageUrl from '@/assets/images/ad-usage.webp'
import ContinueButton from '@/components/ContinueButton'
import DepositAccountAgreementLink from '@/components/DepositAccountAgreementLink'
import { ListItem } from '@/components/ListItem'
import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { analytics } from '@/core/analytics/events'
import { displayIntercomWidget } from '@/core/intercom/intercom-settings'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenImage,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { Pathname } from '@/routes/constants'

function Disclaimer() {
  const t = useOnboardingTranslations()
  const disclaimerBeforeDAA = t('accountUsage.landing.disclaimerBeforeDAA')
  const disclaimerAfterDAA = t('accountUsage.landing.disclaimerAfterDAA')

  return (
    <LegalDisclaimerText color="$charcoal5">
      {disclaimerBeforeDAA} <DepositAccountAgreementLink /> {disclaimerAfterDAA}
    </LegalDisclaimerText>
  )
}

export const AccountUsageLanding = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()

  displayIntercomWidget(true)

  const nextUp = t('accountUsage.landing.nextUp')
  const title = t('accountUsage.landing.title')
  const subtitle = t('accountUsage.landing.subtitle')
  const businessUsageEstimates = t('accountUsage.landing.businessUsageEstimates')

  const onContinue = () => {
    analytics.accountUsage.start()
    navigate(Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT)
  }

  const primaryButton = <ContinueButton testID="account-usage-landing-continue" onPress={onContinue} fullWidth />

  return (
    <ExpectationScreenLayout primaryButton={isMobileSize && primaryButton} disclaimerComponent={<Disclaimer />}>
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf primaryButton={!isMobileSize && primaryButton}>
          <Box sx={{ gap: '$6' }}>
            <Box sx={{ gap: '$2' }}>
              <Body>{nextUp}</Body>
              <ResponsiveOnboardingHeader largerSize="md">{title}</ResponsiveOnboardingHeader>
            </Box>
            <Box sx={{ gap: '$4' }}>
              <CardText size={CardTextSizeEnum.XLG}>{subtitle}</CardText>
              <ListItem icon={ICONS.UsageIcon}>{businessUsageEstimates}</ListItem>
            </Box>
          </Box>
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <ExpectationScreenImage src={adUsageUrl} alt="Money moves at your speed with North One¹" />
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}
