import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fileUploadActions } from '@/core/redux/file-upload-redux/actions'
import { useAppSelector } from '@/core/redux/utils'
import {
  IntercomApiCommandEnum,
  OnboardingApplicationStatus,
  useApplicationStatusSuspenseQuery,
  usePerformIntercomApiCommandMutation,
} from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { isProduction } from '@/utils/environment'

const manualReviewTagId = isProduction() ? '4024325' : '9791227'
const NORTHONE_BOT_ADMIN_ID = '3769142'

export const useCreateManualReviewIntercomConversation = () => {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const { data } = useApplicationStatusSuspenseQuery()
  const applicationStatus = data.me?.ownerBusinesses[0]?.onboarding?.application?.status
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)
  const existingIntercomConversationId = useAppSelector((state) => state.uploadedFileNames.intercomConversationId)
  const intercomMessageBody = t('inAppDocumentCapture.uploadAttachment.intercomMessage')

  const [createIntercomConversation] = usePerformIntercomApiCommandMutation({
    variables: {
      data: {
        businessId,
        command: IntercomApiCommandEnum.CONVERSATION_CREATE,
        body: {
          create_conversation_without_contact_reply: true,
          silent: true,
          from: {
            type: 'admin',
            id: NORTHONE_BOT_ADMIN_ID,
          },
          to: {
            type: 'user',
            user_id: userId,
          },
          body: intercomMessageBody,
        },
      },
    },
  })

  const [tagIntercomConversation] = usePerformIntercomApiCommandMutation()

  useEffect(() => {
    if (!applicationStatus || !businessId || !createIntercomConversation || !tagIntercomConversation || !dispatch)
      return
    if (applicationStatus !== OnboardingApplicationStatus.REVIEWING) return
    if (existingIntercomConversationId) return

    const createAndTagIntercomConversation = async () => {
      const res = await createIntercomConversation()
      const conversationId = res.data?.performIntercomAPICommand.data.conversation_id as string
      if (!conversationId) return console.error('Error creating intercom conversation')

      dispatch(fileUploadActions.setIntercomConversationId(conversationId))
      await tagIntercomConversation({
        variables: {
          data: {
            businessId,
            conversationId,
            command: IntercomApiCommandEnum.CONVERSATION_TAG,
            body: {
              admin_id: NORTHONE_BOT_ADMIN_ID,
              id: manualReviewTagId,
            },
          },
        },
      })
    }
    createAndTagIntercomConversation()
  }, [
    dispatch,
    applicationStatus,
    existingIntercomConversationId,
    businessId,
    createIntercomConversation,
    tagIntercomConversation,
  ])
}

export const useUploadFileToIntercomConversation = ({
  fileData,
  fileName,
  fileContentType,
}: {
  fileName?: string
  fileData?: string
  fileContentType?: string
}) => {
  const conversationId = useAppSelector((state) => state.uploadedFileNames.intercomConversationId)
  const businessId = useAppSelector((state) => state.application.businessId)
  const userId = useAppSelector((state) => state.application.userId)
  const formattedFileData = fileData?.split(',')[1]

  const [uploadFileToIntercom, { loading: uploadFileLoading, error: uploadFileError }] =
    usePerformIntercomApiCommandMutation({
      variables: {
        data: {
          businessId,
          conversationId,
          command: IntercomApiCommandEnum.CONVERSATION_REPLY,
          body: {
            message_type: 'comment',
            type: 'user',
            user_id: userId,
            body: fileName,
            attachment_files: [
              {
                name: fileName,
                content_type: fileContentType,
                data: formattedFileData,
              },
            ],
          },
        },
      },
    })

  return {
    uploadFileToIntercom: fileName && fileContentType && fileData ? uploadFileToIntercom : undefined,
    uploadFileLoading,
    uploadFileError,
  }
}
