import {
  AlertInformationIcon,
  Body,
  BodySizeEnum,
  Box,
  CardText,
  CardTextSizeEnum,
  Icon,
  ICONS,
  LegalDisclaimerText,
  Link,
  Modal,
  PageHeader,
  PageHeaderSizeEnum,
  PrimaryButton,
  SizeEnum,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useState } from 'react'
import { Pressable } from 'react-native'
import { useNavigate } from 'react-router-dom'

import kybAdImageUrl from '@/assets/images/kyb-graphic.webp'
import ContinueButton from '@/components/ContinueButton'
import StandardDisclaimer from '@/components/StandardDisclaimer'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import {
  ExpectationScreenCardHalf,
  ExpectationScreenCardLayout,
  ExpectationScreenImage,
  ExpectationScreenLayout,
} from '@/layouts/ExpectationScreenLayout'
import { TIME_SAVED_ARTICLE } from '@/utils'

import { Pathname } from '../constants'

export default function BusinessDetailsLanding() {
  const navigate = useNavigate()
  const { isMobileSize } = useMobileScreenSize()
  const onContinue = () => navigate(Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS)
  const primaryButton = <ContinueButton testID="business-details-landing-continue" onPress={onContinue} fullWidth />

  return (
    <ExpectationScreenLayout
      primaryButton={isMobileSize && primaryButton}
      disclaimerComponent={<BusinessDetailsDisclaimer />}
    >
      <ExpectationScreenCardLayout>
        <ExpectationScreenCardHalf primaryButton={!isMobileSize && primaryButton}>
          <BusinessDetailsOverview />
        </ExpectationScreenCardHalf>
        <ExpectationScreenCardHalf>
          <ExpectationScreenImage
            src={kybAdImageUrl}
            alt="North One users get banking done up to 90% faster than with their old bank account¹"
          />
        </ExpectationScreenCardHalf>
      </ExpectationScreenCardLayout>
    </ExpectationScreenLayout>
  )
}

const BusinessDetailsOverview = () => {
  const [einModalIsOpen, setEINModalIsOpen] = useState(false)
  const closeModal = () => setEINModalIsOpen(false)
  const { isSoleProp } = useBusinessType()
  const t = useOnboardingTranslations()
  const copy = {
    nextUp: t('businessDetails.expectations.nextUp'),
    header: t('businessDetails.expectations.header'),
    subHeader: t('businessDetails.expectations.subHeader'),
  }
  const bullets = [
    { icon: ICONS.BuildingsIcon, text: t('businessDetails.expectations.bullets.businessName') },
    ...(isSoleProp
      ? []
      : [
          {
            icon: ICONS.DocumentIcon,
            text: t('businessDetails.expectations.bullets.ein'),
            onInfoClick: () => setEINModalIsOpen(true),
          },
        ]),
    { icon: ICONS.MapPinIcon, text: t('businessDetails.expectations.bullets.businessAddressAndPhoneNumber') },
    { icon: ICONS.MoneyIcon, text: t('businessDetails.expectations.bullets.revenue') },
  ]
  return (
    <>
      <EINModal closeModal={closeModal} isVisible={einModalIsOpen} />
      <Box sx={{ gap: '$6', maxWidth: 400 }}>
        <Box sx={{ gap: '$2' }}>
          <Body size={BodySizeEnum.MD}>{copy.nextUp}</Body>
          <PageHeader size={PageHeaderSizeEnum.MD} headingLevel={1}>
            {copy.header}
          </PageHeader>
        </Box>
        <Box sx={{ gap: '$4' }}>
          <CardText size={CardTextSizeEnum.XLG}>{copy.subHeader}</CardText>
          <Box sx={{ gap: '$2' }}>
            {bullets.map(({ icon, text, onInfoClick }, idx) => (
              <Box sx={{ flexDirection: 'row', gap: '$2' }} key={idx}>
                <Icon as={icon} size={SizeEnum.MD} color={'$charcoal7'} />
                <Body size={BodySizeEnum.MD}>{text}</Body>
                {onInfoClick ? (
                  <Pressable onPress={onInfoClick}>
                    <AlertInformationIcon size={SizeEnum.MD} color={'$charcoal5'} />
                  </Pressable>
                ) : null}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const BusinessDetailsDisclaimer = () => {
  const t = useOnboardingTranslations()
  const copy = {
    legalDisclaimer1: t('businessDetails.expectations.legalDisclaimer1'),
    learnTimeSaved: t('businessDetails.expectations.learnTimeSaved'),
    legalDisclaimer2: t('businessDetails.expectations.legalDisclaimer2'),
  }
  return (
    <Box sx={{ gap: '$3' }}>
      <LegalDisclaimerText>
        {copy.legalDisclaimer1}
        <Link href={TIME_SAVED_ARTICLE} isExternal={true}>
          {copy.learnTimeSaved}
        </Link>
      </LegalDisclaimerText>
      <LegalDisclaimerText>{copy.legalDisclaimer2}</LegalDisclaimerText>
      <StandardDisclaimer />
    </Box>
  )
}

const EINModal = ({ closeModal, isVisible }: { closeModal: () => void; isVisible: boolean }) => {
  const t = useOnboardingTranslations()
  const copy = {
    header: t('businessDetails.expectations.einModal.header'),
    bullets: [
      t('businessDetails.expectations.einModal.bullets.confirmationLetter'),
      t('businessDetails.expectations.einModal.bullets.federalTaxReturns'),
      t('businessDetails.expectations.einModal.bullets.irs'),
    ],
    done: t('button.done'),
  }
  return (
    <Modal isVisible={isVisible}>
      <Box sx={{ gap: '$6' }}>
        <PageHeader headingLevel={1} size={PageHeaderSizeEnum.MD}>
          {copy.header}
        </PageHeader>
        <Box sx={{ gap: '$3' }}>
          {copy.bullets.map((text) => (
            <Body key={text} size={BodySizeEnum.MD}>
              {text}
            </Body>
          ))}
        </Box>
        <PrimaryButton fullWidth onPress={closeModal} testID="ein-modal.primary-button">
          {copy.done}
        </PrimaryButton>
      </Box>
    </Modal>
  )
}
