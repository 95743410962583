export const ownership = {
  landing: {
    nextUp: 'Next up:',
    willNeed: {
      SSN: 'Social Security Number (SSN)',
      personal: 'Birthday, home address, phone number, email',
      ownership: 'Ownership percentage',
      financialAdmin: 'Designate a financial administrator',
    },
    corporation: {
      title: 'Create a profile for yourself and any co-owners',
      subtitle:
        "You'll need the following information for everyone who owns 25% or more of the business and anyone with control of your business’ finances:",
    },
    soleProprietorship: {
      title: 'Create your profile',
      subtitle: "You'll need the following information:",
    },
  },
  profile: {
    pageHeading: '{{businessName}} owner profiles',
    subHeading:
      'You will be asked to create an owner profile for every co-owner you have that owns 25% or more of the business.',
    primaryOwnerHeading: 'Complete your owner profile',
    tooltipWhyCollectPersonalInfoContent:
      'North One is required to collect personal information such as first and last name, social security number, job title, date of birth, phone number, and address to verify your identity and ensure compliance with legal and regulatory requirements. This helps prevent fraud, money laundering, and identity theft. Additionally, it allows North One to assess your profile and provide appropriate services tailored to your needs.',
    tooltipWhyCollectOtherAccountHolderInfoContent:
      'North One is required to collect other account holder’s information such as first and last name, social security number, job title, date of birth, phone number, and address to verify their identities and ensure compliance with legal and regulatory requirements. This information is necessary to establish the legitimacy of all account holders and protect the security of the account.',
    coOwnerHeading: "Complete your co-owner's profile",
    addCoOwner: 'Add a legal co-owner',
    addCoOwnerDescription: 'Anyone who owns 25% or more',
    missingSSNAlert:
      'Your session ended before we could verify your identity. For your security we did not save your SSN. Please re-enter it.',
    required: 'Required',
    reviewingYourOwnership: 'Reviewing your ownership',
    deleteCoOwnerModal: {
      heading: 'Are you sure you want to delete this co-owner?',
      subHeading: 'This information will not be saved.',
      toast: 'Your co-owner was deleted.',
    },
    labels: {
      firstName: 'Legal first name',
      lastName: 'Legal last name',
      jobTitle: 'Job title',
      dateOfBirth: 'Date of birth',
      phoneNumber: 'Phone number',
      addressAutocomplete: 'Home address',
      ssnLong: 'Social Security Number (SSN)',
      ssnShort: 'SSN',
      streetAddress: 'Home street address',
      suite: 'Apartment, suite, etc (optional)',
      city: 'City',
      state: 'State',
      zip: 'ZIP Code',
      ownership: 'Ownership',
      ownershipPercentage: 'Ownership percentage of {{businessName}}',
      email: 'Email address',
      coownerCheckboxInvite:
        "Send your co-owner an invite to manage your business' North One Account. They'll receive a debit card and will have the same level of access as you do.",
      financialAdminCheckboxInvite:
        "Send your financial administrator an invite to manage your business' North One Account. They'll receive a debit card and will have the same level of access as you do.",
      invitePending: 'Invite pending',
      delete: 'Delete',
      edit: 'Edit',
    },
    placeholders: {
      addressAutocomplete: 'Search home address',
    },
    helperText: {
      ssn: 'Your SSN is stored securely',
      whyDoWeCollectSSN: 'Why do we collect SSN?',
      whyDoWeCollectSSNPrimaryOwner:
        'North One collects Social Security Numbers (SSNs) to comply with federal regulations, such as the USA PATRIOT Act, which requires financial institutions to verify the identity of their customers.',
      whyDoWeCollectSSNOther: `North One collects {{userType}} Social Security Numbers (SSNs) to comply with federal regulations, such as the USA PATRIOT Act, which requires financial institutions to verify the identity of their customers and other account holders.`,
      streetAddress: 'PO boxes are not accepted',
      whyDoWeCollectContactInfo:
        'North One is required to collect other account holder’s information such as first and last name, social security number, job title, date of birth, phone number, and address to verify their identities and ensure compliance with legal and regulatory requirements. This information is necessary to establish the legitimacy of all account holders and protect the security of the account.',
    },
    emailSuggestion: 'This email address doesn’t look right. Did you mean ',
    errors: {
      ownershipPercentage: "Your business' ownership structure exceeds 100%. Please re-assign the shares.",
    },
    disclaimers: {
      tinAttestation: {
        perjury: 'Under penalty of perjury, I certify that:',
        correctID:
          'The number entered above is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and',
        backupWithholding:
          'I am not subject to backup withholding because: (i) I am exempt from backup withholding, or (ii) I have not been notified by the internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (iii) the IRS has notified me that I am not longer subject to backup withholding; and',
        coverage: {
          intendedToCover: 'I am a U.S. person. This is intended to cover:',
          usCitizen: 'An individual who is a U.S. citizen or U.S. resident alien; or',
          partnership:
            'A partnership, corporation, company, or association created organized in the United States or under the laws of the United States; or',
          estate: 'An estate (other than a foreign estate).',
        },
        exemptFromFATCA: 'I am exempt from Foreign Account Tax Compliance Act (FATCA) reporting.',
        unableToOpenAccount: 'If I am subject to backup withholding, I will not be able to open an account.',
        acknowledgment: 'By clicking Continue, I understand the effect of providing this certification.',
      },
    },
  },
  review: {
    header: '{{businessName}} ownership',
    subHeader: 'Create an owner profile for every co-owner you have that owns 25% or more of the business.',
    whyCollectInfoFromAllOwners: 'Why do we need information from all owners?',
    whyCollectInfoAllOwners:
      'North One needs information from all business owners to comply with regulatory requirements, which mandate the identification and verification of all individuals with significant ownership or control of a business. Additionally, it provides a clear understanding of who has authority over the account, which is crucial for maintaining account security and integrity.',
    addOwner: {
      header: 'Add a legal co-owner',
      subHeader: 'Anyone who owns 25% or more',
    },
    maximumOwnersMessage:
      'You’ve reached the maximum amount of owners (4). You only need to add an owner if they own 25% or more of the business.',
    errors: {
      duplicateEmail:
        'Multiple of your business owners are using the same email. Please submit unique emails for each owner.',
      ownershipPercentageExceeds100: "Your business' ownership structure exceeds 100%. Please re-assign the shares.",
      missingSSN:
        "For your security we do not save SSN information between sessions. Please re-enter each owner's SSN.",
      incompleteProfiles: 'You have incomplete owner profiles. Please finish filling out all owner profiles.',
      ownerError: {
        ownershipPercentage: '{{ownershipPercentage}}% Owner',
        reEnterSSN: 'Re-enter SSN',
        incompleteOwnerProfile: 'Incomplete owner profile',
      },
    },
  },
  addOwner: {
    discardOwnerModal: {
      header: 'Discard owner?',
      subHeader: 'You will lose your progress.',
    },
  },
} as const
