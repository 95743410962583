import { IKYBIndustry } from '../../../routes/business-details/industry-select/types'
import { createAction, IAction, IActionWithPayload, TActionsUnion } from '../utils'
import {
  IAddressState,
  IApplicationState,
  IOnlinePresenceState,
  IOwner,
  IPartnerInformationState,
  ISocialMediaState,
  TBusinessTypeValues,
} from './application-state'

export enum ApplicationActionEnum {
  SET_USER_ID = 'SET_USER_ID',
  SET_APPLICATION_ID = 'SET_APPLICATION_ID',
  SET_BUSINESS_ID = 'SET_BUSINESS_ID',
  SET_TERMS_AND_CONDITIONS_ACCEPTED = 'SET_TERMS_AND_CONDITIONS_ACCEPTED',
  SET_TIN_TERMS_AND_CONDITIONS_ACCEPTED = 'SET_TIN_TERMS_AND_CONDITIONS_ACCEPTED',
  SET_IS_PRIMARY_OWNER = 'SET_IS_PRIMARY_OWNER',
  SET_IS_REFERRAL_HYDRATE_DATA = 'SET_IS_REFERRAL_HYDRATE_DATA',
  SET_BUSINESS_TYPE = 'SET_BUSINESS_TYPE',
  SET_BUSINESS_NAME = 'SET_BUSINESS_NAME',
  SET_TRADE_NAME = 'SET_TRADE_NAME',
  SET_IS_US_CITIZEN = 'SET_IS_US_CITIZEN',
  SET_BUSINESS_DESCRIPTION = 'SET_BUSINESS_DESCRIPTION',
  SET_FULL_TIME_EMPLOYEES = 'SET_FULL_TIME_EMPLOYEES',
  SET_BUSINESS_INDUSTRY = 'SET_BUSINESS_INDUSTRY',
  SET_PROHIBITED_INDUSTRIES_CHECK = 'SET_PROHIBITED_INDUSTRIES_CHECK',
  SET_SHOULD_USE_BUSINESS_ADDRESS_AS_OWNER_ADDRESS = 'SET_SHOULD_USE_BUSINESS_ADDRESS_AS_OWNER_ADDRESS',
  SET_SHOULD_INVITE_OWNER = 'SET_SHOULD_INVITE_OWNER',
  SET_BUSINESS_PHONE_NUMBER = 'SET_BUSINESS_PHONE_NUMBER',
  SET_BUSINESS_ADDRESS = 'SET_BUSINESS_ADDRESS',
  SET_BUSINESS_SOCIAL_MEDIA_LINK = 'SET_BUSINESS_SOCIAL_MEDIA_LINK',
  SET_BUSINESS_REVENUE = 'SET_BUSINESS_REVENUE',
  SET_HAS_STARTED_APPLICATION = 'SET_HAS_STARTED_APPLICATION',
  SET_HAS_REGISTERED_BUSINESS_NAME = 'SET_HAS_REGISTERED_BUSINESS_NAME',
  UPDATE_OWNER = 'UPDATE_OWNER',
  ADD_NEW_OWNER = 'ADD_NEW_OWNER',
  DELETE_OWNER = 'DELETE_OWNER',
  SET_IS_CORP_WITH_MULTIPLE_OWNERS = 'SET_IS_CORP_WITH_MULTIPLE_OWNERS',
  ADD_CONTROL_PERSON_CANDIDATE = 'ADD_CONTROL_PERSON_CANDIDATE',
  SET_CONTROL_PERSON_ID = 'SET_CONTROL_PERSON_ID',
  SET_EIN = 'SET_EIN',
  SET_YEARS_AT_LOCATION = 'SET_YEARS_AT_LOCATION',
  SET_YEARS_IN_OPERATION = 'SET_YEARS_IN_OPERATION',
  SET_HAS_CLICKED_DOCUMENTS_UPLOADED = 'SET_HAS_CLICKED_DOCUMENTS_UPLOADED',
  SET_SOCURE_DEVICE_SESSION_ID = 'SET_SOCURE_DEVICE_SESSION_ID',
  SET_REFERRAL_APPLICATION_DATA = 'SET_REFERRAL_APPLICATION_DATA',
  SET_PARTNER_INFORMATION = 'SET_PARTNER_INFORMATION',
  SET_HAS_COMPLETED_TOP_UP_OPT_IN_PAGE = 'SET_HAS_COMPLETED_TOP_UP_OPT_IN_PAGE',
  PATCH_ONLINE_PRESENCE = 'PATCH_ONLINE_PRESENCE',
  SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED',
  SET_HAS_APPLIED_PROMO_CODE = 'SET_HAS_APPLIED_PROMO_CODE',
  SET_SIGNUP_PROMO_CODE = 'SET_SIGNUP_PROMO_CODE',
}
export const applicationActions = {
  setUserId: (userId: string): IActionWithPayload<ApplicationActionEnum.SET_USER_ID, string> =>
    createAction(ApplicationActionEnum.SET_USER_ID, userId),

  setApplicationId: (applicationId: string): IActionWithPayload<ApplicationActionEnum.SET_APPLICATION_ID, string> =>
    createAction(ApplicationActionEnum.SET_APPLICATION_ID, applicationId),

  setBusinessId: (businessId: string): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_ID, string> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_ID, businessId),

  setTermsAndConditionsAccepted: (
    isAccepted: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_TERMS_AND_CONDITIONS_ACCEPTED, boolean> =>
    createAction(ApplicationActionEnum.SET_TERMS_AND_CONDITIONS_ACCEPTED, isAccepted),

  setTINTermsAndConditionsAccepted: (
    isAccepted: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_TIN_TERMS_AND_CONDITIONS_ACCEPTED, boolean> =>
    createAction(ApplicationActionEnum.SET_TIN_TERMS_AND_CONDITIONS_ACCEPTED, isAccepted),

  setIsPrimaryOwner: (
    isPrimaryOwner: boolean | undefined,
  ): IActionWithPayload<ApplicationActionEnum.SET_IS_PRIMARY_OWNER, boolean | undefined> =>
    createAction(ApplicationActionEnum.SET_IS_PRIMARY_OWNER, isPrimaryOwner),

  setReferralDataWasHydrated: (
    referralDataWasHydrated: boolean | undefined,
  ): IActionWithPayload<ApplicationActionEnum.SET_IS_REFERRAL_HYDRATE_DATA, boolean | undefined> =>
    createAction(ApplicationActionEnum.SET_IS_REFERRAL_HYDRATE_DATA, referralDataWasHydrated),

  setBusinessType: (
    businessName: TBusinessTypeValues,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_TYPE, TBusinessTypeValues> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_TYPE, businessName),

  setBusinessName: (businessName: string): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_NAME, string> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_NAME, businessName),

  setTradeName: (
    tradeName: string | undefined,
  ): IActionWithPayload<ApplicationActionEnum.SET_TRADE_NAME, string | undefined> =>
    createAction(ApplicationActionEnum.SET_TRADE_NAME, tradeName),

  setBusinessIndustry: (
    industry: IKYBIndustry | null,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_INDUSTRY, IKYBIndustry | null> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_INDUSTRY, industry),

  setProhibitedIndustriesCheck: (
    isSelected: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_PROHIBITED_INDUSTRIES_CHECK, boolean> =>
    createAction(ApplicationActionEnum.SET_PROHIBITED_INDUSTRIES_CHECK, isSelected),

  setSameBusinessAndHomeAddressCheck: (
    isSelected: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_SHOULD_USE_BUSINESS_ADDRESS_AS_OWNER_ADDRESS, boolean> =>
    createAction(ApplicationActionEnum.SET_SHOULD_USE_BUSINESS_ADDRESS_AS_OWNER_ADDRESS, isSelected),

  setBusinessDescription: (
    businessDescription: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_DESCRIPTION, string> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_DESCRIPTION, businessDescription),

  setFullTimeEmployees: (
    employees: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_FULL_TIME_EMPLOYEES, string> =>
    createAction(ApplicationActionEnum.SET_FULL_TIME_EMPLOYEES, employees),

  setBusinessPhoneNumber: (
    phoneNumber: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_PHONE_NUMBER, string> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_PHONE_NUMBER, phoneNumber),

  setBusinessAddress: (
    address: IAddressState,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_ADDRESS, IAddressState> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_ADDRESS, address),

  setBusinessSocialMediaLink: (
    socialMediaLinks: ISocialMediaState,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_SOCIAL_MEDIA_LINK, ISocialMediaState> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_SOCIAL_MEDIA_LINK, socialMediaLinks),

  setBusinessRevenue: (
    businessRevenue: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_BUSINESS_REVENUE, string> =>
    createAction(ApplicationActionEnum.SET_BUSINESS_REVENUE, businessRevenue),

  setVerifyUSCitizen: (
    isUSCitizen: boolean | undefined,
  ): IActionWithPayload<ApplicationActionEnum.SET_IS_US_CITIZEN, boolean | undefined> =>
    createAction(ApplicationActionEnum.SET_IS_US_CITIZEN, isUSCitizen),

  setHasStartedApplication: (
    hasStartedApplication: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_HAS_STARTED_APPLICATION, boolean> =>
    createAction(ApplicationActionEnum.SET_HAS_STARTED_APPLICATION, hasStartedApplication),

  setHasRegisteredBusinessName: (
    hasRegisteredBusinessName: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_HAS_REGISTERED_BUSINESS_NAME, boolean> =>
    createAction(ApplicationActionEnum.SET_HAS_REGISTERED_BUSINESS_NAME, hasRegisteredBusinessName),

  updateOwner: (payload: {
    updatedOwnerFields: Partial<IOwner>
    ownerId: string
  }): IActionWithPayload<
    ApplicationActionEnum.UPDATE_OWNER,
    {
      updatedOwnerFields: Partial<IOwner>
      ownerId: string
    }
  > => createAction(ApplicationActionEnum.UPDATE_OWNER, payload),

  addNewOwner: (ownerId?: string): IActionWithPayload<ApplicationActionEnum.ADD_NEW_OWNER, string | undefined> =>
    createAction(ApplicationActionEnum.ADD_NEW_OWNER, ownerId),

  setIsInviteCheckboxSelected: (payload: {
    shouldSendInvite: Partial<IOwner>
    ownerId: string
  }): IActionWithPayload<
    ApplicationActionEnum.SET_SHOULD_INVITE_OWNER,
    {
      shouldSendInvite: Partial<IOwner>
      ownerId: string
    }
  > => createAction(ApplicationActionEnum.SET_SHOULD_INVITE_OWNER, payload),

  deleteOwner: (ownerId: string): IActionWithPayload<ApplicationActionEnum.DELETE_OWNER, string> =>
    createAction(ApplicationActionEnum.DELETE_OWNER, ownerId),

  setIsCorpWithMultipleOwners: (
    isCorpWithMultipleOwners: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_IS_CORP_WITH_MULTIPLE_OWNERS, boolean> =>
    createAction(ApplicationActionEnum.SET_IS_CORP_WITH_MULTIPLE_OWNERS, isCorpWithMultipleOwners),

  addControlPersonCandidate: (): IAction<ApplicationActionEnum.ADD_CONTROL_PERSON_CANDIDATE> =>
    createAction(ApplicationActionEnum.ADD_CONTROL_PERSON_CANDIDATE),

  setControlPersonId: (ownerId: string): IActionWithPayload<ApplicationActionEnum.SET_CONTROL_PERSON_ID, string> =>
    createAction(ApplicationActionEnum.SET_CONTROL_PERSON_ID, ownerId),

  setEIN: (ein: string): IActionWithPayload<ApplicationActionEnum.SET_EIN, string> =>
    createAction(ApplicationActionEnum.SET_EIN, ein),

  setYearsAtLocation: (
    yearsAtLocation: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_YEARS_AT_LOCATION, string> =>
    createAction(ApplicationActionEnum.SET_YEARS_AT_LOCATION, yearsAtLocation),

  setYearsInOperation: (yearsInOperation?: number) =>
    createAction(ApplicationActionEnum.SET_YEARS_IN_OPERATION, yearsInOperation),

  setHasClickedDocumentsUploaded: (
    hasClickedDocumentsUploaded: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_HAS_CLICKED_DOCUMENTS_UPLOADED, boolean> =>
    createAction(ApplicationActionEnum.SET_HAS_CLICKED_DOCUMENTS_UPLOADED, hasClickedDocumentsUploaded),

  setSocureDeviceSessionId: (
    socureDeviceSessionId: string,
  ): IActionWithPayload<ApplicationActionEnum.SET_SOCURE_DEVICE_SESSION_ID, string> =>
    createAction(ApplicationActionEnum.SET_SOCURE_DEVICE_SESSION_ID, socureDeviceSessionId),

  setReferralApplicationData: (data: IApplicationState) =>
    createAction(ApplicationActionEnum.SET_REFERRAL_APPLICATION_DATA, data),

  setPartnerInformation: (data: IPartnerInformationState) =>
    createAction(ApplicationActionEnum.SET_PARTNER_INFORMATION, data),

  patchOnlinePresence: (data: IOnlinePresenceState) => createAction(ApplicationActionEnum.PATCH_ONLINE_PRESENCE, data),

  setEmailVerified: (emailVerified: boolean): IActionWithPayload<ApplicationActionEnum.SET_EMAIL_VERIFIED, boolean> =>
    createAction(ApplicationActionEnum.SET_EMAIL_VERIFIED, emailVerified),
  setHasAppliedPromoCode: (
    hasAppliedPromoCode: boolean,
  ): IActionWithPayload<ApplicationActionEnum.SET_HAS_APPLIED_PROMO_CODE, boolean> =>
    createAction(ApplicationActionEnum.SET_HAS_APPLIED_PROMO_CODE, hasAppliedPromoCode),
  setSignupPromoCode: (promoCode: string): IActionWithPayload<ApplicationActionEnum.SET_SIGNUP_PROMO_CODE, string> =>
    createAction(ApplicationActionEnum.SET_SIGNUP_PROMO_CODE, promoCode),
}
export type TApplicationActions = TActionsUnion<typeof applicationActions>
