import { Box, CardText, CardTextSizeEnum, ICONS, LegalDisclaimerText } from '@northone/ui-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import { ListItem } from '@/components/ListItem'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'

import { Pathname } from '../../constants'
import { EINModal } from '../components/EINModal'
import { SSNModal } from '../components/SSNModal'

const RequirementsBusinessBlock = () => {
  const t = useOnboardingTranslations()
  const [EINModalVisible, setEINModalVisible] = useState(false)

  const toggleEINModal = () => {
    setEINModalVisible(!EINModalVisible)
  }

  const onClose = () => {
    setEINModalVisible(false)
  }
  return (
    <Box sx={{ gap: '$4' }}>
      <CardText color={'$charcoal7'} size={CardTextSizeEnum.XLG}>
        {t('gettingStarted.business.subtitle')}
      </CardText>
      <Box sx={{ gap: '$2' }}>
        <ListItem icon={ICONS.DocumentIcon} onClick={toggleEINModal} showInformationIcon>
          {t('gettingStarted.business.employerId')}
        </ListItem>

        <ListItem icon={ICONS.MoneyIcon}>{t('gettingStarted.business.revenue')}</ListItem>
      </Box>
      <EINModal isEINVisible={EINModalVisible} onClose={onClose} />
    </Box>
  )
}

const RequirementsPersonalBlock = () => {
  const t = useOnboardingTranslations()
  const [SSNModalVisible, setSSNModalVisible] = useState(false)

  const toggleSSNModal = () => {
    setSSNModalVisible(!SSNModalVisible)
  }

  const onClose = () => {
    setSSNModalVisible(false)
  }
  return (
    <Box sx={{ gap: '$4' }}>
      <CardText color={'$charcoal7'} size={CardTextSizeEnum.XLG}>
        {t('gettingStarted.personal.subtitle')}
      </CardText>

      <Box sx={{ gap: '$2' }}>
        <ListItem icon={ICONS.ProtectedIcon} onClick={toggleSSNModal} showInformationIcon>
          {t('gettingStarted.personal.ssn')}
        </ListItem>

        <ListItem icon={ICONS.IdentificationIcon}>{t('gettingStarted.personal.identification')}</ListItem>

        <ListItem icon={ICONS.PieChartIcon}>{t('gettingStarted.personal.ownership')}</ListItem>
      </Box>
      <SSNModal isSSNVisible={SSNModalVisible} onClose={onClose} />
    </Box>
  )
}

export default function GettingStarted() {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()

  const onContinuePress = () => {
    navigate(Pathname.WELCOME_BUSINESS_TYPE, { replace: true })
  }

  const title = t('gettingStarted.title')

  const FooterDisclaimer = () => {
    return <LegalDisclaimerText color={'$charcoal5'}>{t('disclaimers.standard')}</LegalDisclaimerText>
  }

  const continueButton = <ContinueButton fullWidth testID="continue-button" onPress={onContinuePress} />

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={continueButton}
      disclaimerComponent={<FooterDisclaimer />}
      showProgressAutoSavedNote
    >
      <RequirementsBusinessBlock />
      <RequirementsPersonalBlock />
    </BaseContentLayout>
  )
}
